














import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component
export default class RefundPolicyList extends Vue {
  @Prop({
    required: true
  })
  public type!: string;

  public policyList = [
    {
      type: 'strict',
      list: [
        '노쇼 0% 환불',
        '방문일 기준 당일에 취소한 예약 0% 환불',
        '방문일 기준 1일 전에 취소한 예약 20% 환불',
        '방문일 기준 2일 전에 취소한 예약 50% 환불',
        '방문일 기준 3일 전에 취소한 예약 100% 환불'
      ]
    },
    {
      type: 'normal',
      list: [
        '노쇼 0% 환불',
        '방문일 기준 당일에 취소한 예약 20% 환불',
        '방문일 기준 1일 전에 취소한 예약 50% 환불',
        '방문일 기준 2일 전에 취소한 예약 100% 환불'
      ]
    },
    {
      type: 'flexible',
      list: [
        '노쇼 0% 환불',
        '방문일 기준 당일에 취소한 예약 50% 환불',
        '방문일 기준 1일 전에 취소한 예약 100% 환불'
      ]
    }
  ]
}
